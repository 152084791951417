package ru.ontando.auth.web.react

import react.RComponent
import react.RProps
import react.RState
import react.setState
import ru.ontando.auth.web.ClientContext
import ru.ontando.auth.web.event.EventType
import ru.ontando.auth.web.util.Logger

interface ClientContextProps : RProps {
    var context: ClientContext
}

abstract class AbstractComponent<State : RState>(props: ClientContextProps) : RComponent<ClientContextProps, State>(props) {

    companion object {
        private val LOGGER = Logger(AbstractComponent::class)
    }

    private val eventManager get() = props.context.eventManager

    open fun subscribeEvents(): List<EventType> = emptyList()
    open fun State.rebuildStateFromContext(context: ClientContext) {}

    override fun State.init(props: ClientContextProps) {
        LOGGER.info("[init] ${this@AbstractComponent::class.simpleName}")
        rebuildStateFromContext(props.context)
    }

    override fun componentDidMount() {
        eventManager.subscribe(this, subscribeEvents()) {
            setState {
                rebuildStateFromContext(props.context)
            }
        }
    }

    override fun componentWillUnmount() {
        eventManager.unsubscribe(this)
    }
}