package ru.ontando.auth.web.network

import ru.ontando.auth.web.ClientContext
import ru.ontando.auth.web.ClientState
import ru.ontando.auth.web.util.Logger
import ru.ontando.auth.web.util.jQuery
import ru.ontando.auth.web.util.jQueryAjaxSettings
import kotlin.reflect.KClass

abstract class RemoteService(
    protected val context: ClientContext,
    private val urlPrefix: String
) {

    companion object {
        val LOGGER = Logger(RemoteService::class)
    }

    protected val state get() = context.state
    protected val eventManager get() = context.eventManager

    fun apiCall(url: String, initData: dynamic.() -> Unit, passSessionToken: Boolean = true) {
        apiCall<Unit>(
            url = url,
            initData = initData,
            successCallback = {},
            passSessionToken = passSessionToken
        )
    }

    fun <ResponseType : Any> apiCall(
        url: String,
        initData: dynamic.() -> Unit,
        successCallback: (ResponseType) -> Unit,
        passSessionToken: Boolean = true
    ) {
        LOGGER.info("[apiCall] url=[\"$urlPrefix\", \"$url\"]")
        jQuery.ajax(urlPrefix + url, jQueryAjaxSettings {
            method = "POST"

            data = js("{}")

            if (passSessionToken) {
                data.sessionToken = state.session?.sessionToken
            }
            initData(data)
            LOGGER.info("[apiCall] url=[\"$urlPrefix\", \"$url\"]; requestData=${JSON.stringify(data)}")

            success = { data, _, _ ->
                LOGGER.info("[apiCall] url=[\"$urlPrefix\", \"$url\"]; successData=${JSON.stringify(data)}")

                val dataObj= data.unsafeCast<ResponseType>()

                successCallback(dataObj)

            }
        })

    }
}