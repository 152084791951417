package ru.ontando.auth.web.network

import ru.ontando.auth.data.RedirectionRequestDTO
import ru.ontando.auth.data.SessionDataDTO
import ru.ontando.auth.web.ClientContext
import ru.ontando.auth.web.event.EventType
import kotlinx.browser.window
import ru.ontando.auth.data.TelegramLoginRequestDTO

class AuthService(context: ClientContext) : RemoteService(context, "/api/auth") {

    fun reconnect() {
        apiCall(
            url = "/reconnect",
            initData = {
                registrationToken = state.storedRegistrationToken
            },
            successCallback = { response: SessionDataDTO ->
                state.session = response
                state.storedRegistrationToken = response.registrationToken

                eventManager.fire(EventType.SESSION_REFRESH)
            },
            passSessionToken = false
        )
    }

    fun loginWithGoogle(googleToken: String) {
        apiCall(url = "/login-google",
            initData = {
                this.googleToken = googleToken
            },
            successCallback = { response: SessionDataDTO? ->
                if (response != null) {
                    state.session = response
                    state.storedRegistrationToken = response.registrationToken
                }

                eventManager.fire(EventType.SESSION_REFRESH)
            }
        )
    }

    fun loginWithTelegram(request: TelegramLoginRequestDTO) {
        apiCall(url = "/login-telegram",
            initData = {
                this.requestString = JSON.stringify(request)
            },
            successCallback = { response: SessionDataDTO? ->
                if (response != null) {
                    state.session = response
                    state.storedRegistrationToken = response.registrationToken
                }

                eventManager.fire(EventType.SESSION_REFRESH)
            }
        )
    }

    fun redirect(redirectionToken: String) {
        apiCall(url = "/redirect",
            initData = {
                this.redirectionToken = redirectionToken
            },
            successCallback = { response: RedirectionRequestDTO? ->
                if (response != null) {
                    window.location.href = response.redirectionURL
                }
            }
        )
    }

}