package ru.ontando.auth.web.util

import org.w3c.dom.Element
import org.w3c.dom.ImageBitmapRenderingContextSettings
import org.w3c.dom.Node

external interface jQueryItem {

    // Events
    fun ready(init: () -> Unit)
    fun click(run: () -> Unit)
    fun keydown(handler: (jQueryKeyDownEvent) -> Unit)
    fun mousemove(handler: (jQueryMouseMoveEvent) -> Unit)

    // JQuery
    fun find(selector: String): jQueryItem

    // DOM
    fun append(element: Node): jQueryItem
    fun append(element: jQueryItem): jQueryItem
    operator fun get(index: Int): Element
    fun children(): jQueryItem
    fun detach(): jQueryItem

    // Properties
    fun `val`(): Any?
    fun html(html: String): jQueryItem
    fun text(text: String): jQueryItem
    fun text(): String

    // Style
    fun addClass(className: String): jQueryItem
    fun removeClass(className: String): jQueryItem
    fun hide(): jQueryItem
    fun show(): jQueryItem
    fun css(cssProperty: String, cssValue: String): jQueryItem

}

external fun jQuery(selector: String) : jQueryItem

external fun jQuery(selector: Node) : jQueryItem

external object jQuery {

    fun ajax(url: String, settings: jQueryAjaxSettings = definedExternally)

}

external interface jQueryAjaxSettings {
    var method: String?
    var data: dynamic
    var complete: ((jqXHR: dynamic, textStatus: dynamic) -> Unit)?
    var success: ((data: Any?, textStatus: dynamic, jqXHR: dynamic) -> Unit)?
}

fun jQueryAjaxSettings(init: jQueryAjaxSettings.() -> Unit): jQueryAjaxSettings {
    val reply: jQueryAjaxSettings = js("{}")
    init(reply)
    return reply
}


external interface jQueryKeyDownEvent {

    val which: Int

    fun preventDefault()
}

external interface jQueryMouseMoveEvent {
    val pageX: Int
    val pageY: Int

    @Deprecated("browser specific")
    val offsetX: Int
    @Deprecated("browser specific")
    val offsetY: Int
}