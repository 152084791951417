package ru.ontando.auth.web.react

import kotlinx.html.id
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.div
import ru.ontando.auth.web.google.GoogleAPI
import ru.ontando.auth.web.google.GoogleAPIHelper
import ru.ontando.auth.web.google.GoogleAPI_auth2_GoogleUser_AuthResponce
import ru.ontando.auth.web.google.render

interface GoogleAPILoginButtonProps : RProps {
    var onSuccess: (GoogleAPI_auth2_GoogleUser_AuthResponce) -> Unit
}

class GoogleAPILoginButton(props: GoogleAPILoginButtonProps) : RComponent<GoogleAPILoginButtonProps, RState>(props) {

    companion object {
        const val googleAPIButtonId: String = "g-api-button-id"
    }

    override fun RBuilder.render() {
        div {
            attrs.id = googleAPIButtonId
        }
    }

    override fun componentDidMount() {
        GoogleAPIHelper.auth2 {

            GoogleAPI.signin2.render(googleAPIButtonId) {
                onsuccess = { user ->
                    props.onSuccess(user.getAuthResponse())
                }
            }

        }
    }
}

fun RBuilder.GoogleAPILoginButton(onSuccess: (GoogleAPI_auth2_GoogleUser_AuthResponce) -> Unit) = child(GoogleAPILoginButton::class) {
    attrs.onSuccess = onSuccess
}