package ru.ontando.auth.web.telegramm

import ru.ontando.auth.data.TelegramLoginRequestDTO
import ru.ontando.auth.web.ClientContext


interface TelegramUser {
    val id: String
    val first_name: String
    val last_name: String
    val username: String
    val photo_url: String
    val auth_date: String
    val hash: String
}

fun TelegramUser.toDTO() = TelegramLoginRequestDTO(
    id = id,
    firstName = first_name,
    lastName = last_name,
    username = username,
    photoUrl = photo_url,
    authDate = auth_date,
    hash = hash
)

object TelegramCommonCallbacks {

    fun onTelegramAuth(context: ClientContext): (TelegramUser) -> Unit {
        return { user ->
            context.serviceProvider.authService.loginWithTelegram(user.toDTO())
        }
    }
}