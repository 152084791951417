package ru.ontando.auth.data

class SessionDataDTO constructor(
    val registrationToken: String,
    val sessionToken: String,
    val loggedIn: Boolean,
    val linkedProfileInformation: Array<LinkedProfileDTO>,
    val linkedServiceInformation: Array<LinkedServiceDTO>,
)

class LinkedProfileDTO(
    val typeId: Int,
    val displayName: String,
    val image: String?
)
class LinkedServiceDTO(
    val serviceName: String,
)

val LinkedProfileDTO.type get() = LinkedProfileType.values()[typeId]

enum class LinkedProfileType {
    GOOGLE,
    TELEGRAMM
}