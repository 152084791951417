package ru.ontando.auth.web

import ru.ontando.auth.web.event.EventManager
import ru.ontando.auth.web.network.ServiceProvider

class ClientContext {

    val state = ClientState()

    val args = ClientArgs()

    val eventManager = EventManager()

    val serviceProvider = ServiceProvider(this)

}