package ru.ontando.auth.web.react

import react.RBuilder
import react.RState
import react.dom.img
import ru.ontando.auth.data.LinkedProfileDTO
import ru.ontando.auth.data.LinkedProfileType
import ru.ontando.auth.data.LinkedServiceDTO
import ru.ontando.auth.data.type
import ru.ontando.auth.web.ClientContext
import ru.ontando.auth.web.event.EventType
import ru.ontando.auth.web.google.GoogleAPI_auth2_GoogleUser_AuthResponce
import ru.ontando.auth.web.util.Logger
import ru.ontando.lib.bootstrap.*

interface ReactAppState : RState {
    var isConnected: Boolean
    var isLoggedIn: Boolean
    var profiles: Array<LinkedProfileDTO>
    var services: List<LinkedServiceDTO>
}

class ReactApp(props: ClientContextProps) : AbstractComponent<ReactAppState>(props) {

    companion object {
        val LOGGER = Logger(ReactApp::class)
    }

    override fun subscribeEvents()
            = listOf(EventType.SESSION_REFRESH)

    override fun ReactAppState.rebuildStateFromContext(context: ClientContext) {
        isConnected = context.state.session != null
        isLoggedIn = context.state.session?.loggedIn == true
        profiles = context.state.session?.linkedProfileInformation ?: emptyArray()
        services = context.state.session?.linkedServiceInformation?.toList() ?: emptyList()

        LOGGER.info("[rebuildStateFromContext] ${JSON.stringify(this)}")
    }

    override fun RBuilder.render() {

        container {
            row(classes = "justify-content-md-center") {
                col(size = 6) {

                    if (!state.isConnected) {

                        card {
                            cardHeader {
                                +"Connecting"
                            }
                            cardBody {
                                +"Connecting to authentication servers"
                            }
                        }

                    } else {

                        if (state.isLoggedIn) {

                            card {
                                cardHeader {
                                    +"Ok"
                                }
                                cardBody {
                                    +"You are logged in"
                                }
                                cardBody {
                                    table(state.profiles.toList(), size = TableSize.SMALL) {
                                        column(title = "Service", cell = {
                                            + it.type.name
                                        })
                                        column(title = "Profile", cell = {
                                            + it.displayName
                                        })
                                        column(title = "Avatar", cell = {
                                            val image = it.image
                                            if (image != null) {
                                                img {
                                                    attrs.width = "50px"
                                                    attrs.src = image
                                                }
                                            }
                                        })
                                    }
                                }

                                cardBody {
                                    cardText {
                                        + "Your auth data are used in services:"
                                    }
                                    table(state.services, size = TableSize.SMALL) {
                                        column(title = "Service", cell = {
                                            + it.serviceName
                                        })
                                    }
                                }
                            }

                            card {
                                cardHeader {
                                    + "You can link additional accounts"

                                }
                                if (state.profiles.none { it.type == LinkedProfileType.GOOGLE }) {
                                    cardBody {
                                        +"Log in with google"

                                        GoogleAPILoginButton { onLoginGoogle(it) }

                                    }
                                }
                                if (state.profiles.none { it.type == LinkedProfileType.TELEGRAMM }) {
                                    cardBody {
                                        +"Log in with telegram"

                                        TelegrammAPILoginButton(props)

                                    }
                                }
                            }
                        } else {
                            card {
                                cardHeader {
                                     +"You are not logged in"
                                }
                                cardBody {
                                    +"Log in with google"

                                    GoogleAPILoginButton { onLoginGoogle(it) }
                                }
                                cardBody {
                                    +"Log in with telegram"

                                    TelegrammAPILoginButton(props)

                                }
                            }

                        }

                    }
                }
            }
        }
    }

    private fun onLoginGoogle(user: GoogleAPI_auth2_GoogleUser_AuthResponce) {
        props.context.serviceProvider.authService.loginWithGoogle(user.idToken)
    }


}

fun RBuilder.ReactApp(context: ClientContext) = child(ReactApp::class) {

    attrs.context = context
}