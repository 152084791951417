package ru.ontando.auth.web.google

import kotlin.browser.window

object GoogleAPIHelper {

    private var auth2: GoogleAPI_auth2? = null
    private val auth2ready = ArrayList<GoogleAPI_auth2.() -> Unit>()

    fun init() {

        GoogleGlobalFunctionList.values()

    }

    fun auth2(action: GoogleAPI_auth2.() -> Unit) {
        val auth2Castable = auth2
        if (auth2Castable != null) {
            action(auth2Castable)
        } else {
            auth2ready.add(action)
        }
    }

    fun setAuth2(auth2: GoogleAPI_auth2) {
        this.auth2 = auth2
        auth2ready.forEach { it(auth2) }
    }

}


private object GoogleCallbacks {

    fun initGoogleAPI() {
        GoogleAPI.load("auth2") {

            GoogleAPIHelper.setAuth2(GoogleAPI.auth2)

            GoogleAPI.auth2.init {
                clientId = "24386172000-lib9kf5booocjhhj1k11ae95b6nkhp6u.apps.googleusercontent.com"
            }

        }
    }
}

enum class GoogleGlobalFunctionList(val functionName: String, function: () -> Unit) {

    INIT("init", GoogleCallbacks::initGoogleAPI),

    ;

    val fullName: String = "g_api_callback_$functionName"

    init {
        window.asDynamic()[fullName] = function
    }

}