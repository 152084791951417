package ru.ontando.auth.data

class TelegramLoginRequestDTO(
    val id: String,
    val firstName: String,
    val lastName: String,
    val username: String,
    val photoUrl: String,
    val authDate: String,
    val hash: String
)