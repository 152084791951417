package ru.ontando.auth.web

import ru.ontando.auth.data.SessionDataDTO
import kotlin.browser.localStorage

class ClientState {

    var storedRegistrationToken: String?
        get() = localStorage.getItem("ClientState.storedRegistrationToken")
        set(value) {
            if (value != null) {
                localStorage.setItem("ClientState.storedRegistrationToken", value)
            } else {
                localStorage.removeItem("ClientState.storedRegistrationToken")
            }
        }

    var session: SessionDataDTO? = null

}