package ru.ontando.auth.web

import org.w3c.dom.url.URLSearchParams
import kotlin.browser.window
import kotlin.js.RegExp

class ClientArgs {

    var redirectionToken: String? = null
        private set

    fun init() {
        val params = URLSearchParams(window.location.search)

        redirectionToken = params.get("redirectionToken")
    }

}
