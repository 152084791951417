package ru.ontando.auth.web.util

import ru.ontando.auth.web.ClientContext
import ru.ontando.auth.web.telegramm.TelegramCommonCallbacks
import kotlinx.browser.window

class GlobalFunctionRegistry(val context: ClientContext) {

    fun init() {
        KnownGlobalFunctions.values().forEach { function ->
            registerStatelessCallback(function.id, function.action)
        }
    }

    fun registerStatelessCallback(id: String, action: (context: ClientContext) -> Any) {

        window.asDynamic()[id] = action(context)
    }

}

enum class KnownGlobalFunctions(val id: String, val action: (context: ClientContext) -> Any) {
    TELEGRAM_ON_AUTH("telegram_on_auth_callback", action = TelegramCommonCallbacks::onTelegramAuth)
}
