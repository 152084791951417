package ru.ontando.auth.web.event

import ru.ontando.auth.web.Client
import ru.ontando.auth.web.react.AbstractComponent
import ru.ontando.auth.web.util.Logger

typealias EventHandler = () -> Unit
typealias TypedEventHandler = (type: EventType) -> Unit

enum class EventType {
    SESSION_REFRESH
}

class EventManager {
    companion object {
        val LOGGER = Logger(EventManager::class)
    }

    private val byEvent: MutableMap<EventType, SubscriptionsData> = HashMap()

    fun fire(event: EventType) {
        LOGGER.info("[fire] ${event.name}")

        byEvent[event]?.fire()
    }

    fun subscribe(component: AbstractComponent<*>, events: List<EventType>, action: EventHandler) {
        events.forEach { event ->
            LOGGER.info("[subscribe] ${component::class.simpleName} -> ${event.name}")

            byEvent
                .getOrPut(event) { SubscriptionsData() }
                .add(component, action)
        }
    }

    fun subscribe(client: Client, action: TypedEventHandler) {
        EventType.values().forEach { event ->
            LOGGER.info("[subscribe] ${client::class.simpleName} -> ${event.name}")

            byEvent
                .getOrPut(event) { SubscriptionsData() }
                .addPersistent { action(event) }
        }
    }

    fun unsubscribe(component: AbstractComponent<*>) {
        byEvent.values.forEach { data ->
            data.remove(component)
        }
    }

}

private class SubscriptionsData {
    private val byComponent: MutableMap<AbstractComponent<*>, EventHandler> = HashMap()
    private val persistent: MutableList<EventHandler> = ArrayList()

    fun fire() {
        byComponent.values.forEach { it.invoke() }
        persistent.forEach { it.invoke() }
    }

    fun add(component: AbstractComponent<*>, action: () -> Unit) {
        byComponent[component] = action
    }

    fun remove(component: AbstractComponent<*>) {
        byComponent.remove(component)
    }

    fun addPersistent(action: EventHandler) {
        persistent.add(action)
    }
}