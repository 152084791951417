package ru.ontando.auth.web.util

import kotlin.js.Date
import kotlin.reflect.KClass

class Logger(val name: String) {

    constructor(type: KClass<*>) : this(type.simpleName ?: "<unknown>")

    fun info(message: String) {
        console.log("[${Date().toISOString()}] [$name] $message")
    }
}
