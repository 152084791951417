package ru.ontando.auth.web

import ru.ontando.auth.web.event.EventType
import ru.ontando.auth.web.google.GoogleAPIHelper
import ru.ontando.auth.web.network.ServiceProvider
import ru.ontando.auth.web.react.ReactApp
import ru.ontando.auth.web.util.GlobalFunctionRegistry
import ru.ontando.auth.web.util.jQuery
import kotlin.browser.document
import kotlin.browser.window

class Client {

    private val context = ClientContext()

    fun init() {

        subscribeEvents()

        context.args.init()

        GlobalFunctionRegistry(context).init()

        GoogleAPIHelper.init()

        context.serviceProvider.authService.reconnect()

        jQuery(document).ready {

            jQuery("#pre-loading-popup").hide()

            val reactAppElement = jQuery("#react-app")

            react.dom.render(reactAppElement[0]) {
                ReactApp(context)
            }
        }

    }

    private fun subscribeEvents() {
        context.eventManager.subscribe(this) { event ->
            when (event) {
                EventType.SESSION_REFRESH -> {
                    val redirectionToken = context.args.redirectionToken
                    if (context.state.session?.loggedIn == true && redirectionToken != null) {
                        context.serviceProvider.authService.redirect(redirectionToken)
                    }
                }
            }
        }
    }

}