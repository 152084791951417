package ru.ontando.auth.web.google

import ru.ontando.auth.web.util.objectFromLambda


@JsName("gapi")
external object GoogleAPI {
    fun load(moduleName: String, callback: () -> Unit)

    val auth2: GoogleAPI_auth2
    val signin2: GoogleAPI_signin2
}

external class GoogleAPI_auth2 {

    fun init(params: GoogleAPI_auth2_init_params)

}

external class GoogleAPI_signin2 {

    fun render(id: String, params: GoogleAPI_signin2_render_params)

}

external interface GoogleAPI_auth2_init_params {
    @JsName("client_id")
    var clientId: String
}

external interface GoogleAPI_signin2_render_params {

    var scope: String?
    var width: Int?
    var height: Int
    var longtitle: Boolean
    var theme: String
    var onsuccess: (GoogleAPI_auth2_GoogleUser) -> Unit
    var onfailure: () -> Unit
}

external interface GoogleAPI_auth2_GoogleUser {
    fun getAuthResponse(): GoogleAPI_auth2_GoogleUser_AuthResponce
}

external interface GoogleAPI_auth2_GoogleUser_AuthResponce {
    @JsName("id_token")
    val idToken: String
}


fun GoogleAPI_auth2.init(params: GoogleAPI_auth2_init_params.() -> Unit) {
    init(objectFromLambda(params))
}

fun GoogleAPI_signin2.render(id: String, params: GoogleAPI_signin2_render_params.() -> Unit) {
    render(id, objectFromLambda(params))
}