package ru.ontando.auth.web.react

import kotlinx.browser.document
import kotlinx.dom.createElement
import org.w3c.dom.Node
import react.RBuilder
import react.RState
import react.ReactDsl
import react.dom.div
import react.dom.script
import ru.ontando.auth.web.google.GoogleAPI_auth2_GoogleUser_AuthResponce
import ru.ontando.auth.web.util.KnownGlobalFunctions

class TelegrammAPILoginButton(props: ClientContextProps) : AbstractComponent<RState>(props) {

    val divPosition = React.createRef<Node>()

    override fun componentDidMount() {
        val script = document.createElement("script")

        script.asDynamic().async = true
        script.asDynamic().src = "https://telegram.org/js/telegram-widget.js?14"
        script.asDynamic().dataset["telegramLogin"] = "ontando_auth_bot"
        script.asDynamic().dataset["size"] = "large"
        script.asDynamic().dataset["requestAccess"] = "write"
        script.asDynamic().dataset["onauth"] = "${KnownGlobalFunctions.TELEGRAM_ON_AUTH.id}(user)"

        divPosition.current?.appendChild(script)
    }

    override fun RBuilder.render() {

        div {
            ref = divPosition
        }

    }

}

fun RBuilder.TelegrammAPILoginButton(props: ClientContextProps) = child(TelegrammAPILoginButton::class) {
    attrs.context = props.context
}